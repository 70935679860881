import React from "react";
import { Link } from "gatsby";

class Footer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <footer
          style={{
            backgroundColor: "#111111",
            borderTop: "10px solid #00ff00",
            padding: "40px",
            position: "relative",
            zIndex: "2"
          }}
        >
          <div className="columns">
            <div className="column" style={{ textAlign: "left" }}>
              <Link
                to="/en/"
                style={{
                  boxShadow: "5px 5px #00ff00",
                  backgroundColor: "black",
                  outlineWidth: "0 !important"
                }}
              >
                <img
                  loading="lazy"
                  className="navbar-item"
                  style={{ marginBottom: "0px" }}
                  src="/img/logo-rexer.png"
                  alt="Rexer - inżynieria środowiska"
                  width="200"
                  height="auto"
                />
              </Link>
            </div>

            <div
              className="column"
              style={{ textAlign: "left", color: "white" }}
            >
              <h2
                style={{
                  color: "white",
                  fontSize: ".8em",
                  textTransform: "uppercase"
                }}
              >
                <b>HEADQUARTERS</b>
              </h2>
              <br />
              <p style={{ fontSize: ".7em" }}>
                VISION OFFICES <br />
                Legnicka 55F/330,
                <br />
                54-203 Wrocław
              </p>
            </div>

            <div
              className="column"
              style={{ textAlign: "left", color: "white" }}
            >
              <h2
                style={{
                  color: "white",
                  fontSize: ".8em",
                  textTransform: "uppercase"
                }}
              >
                <b>REXER Ltd.</b>
              </h2>
              <br />
              <p style={{ fontSize: ".7em" }}>
                TIN: 894-305-68-63 <br />
                NBRN: 022525027
                <br />
                NCR: 0000905765
              </p>
            </div>

            <div
              className="column"
              style={{ textAlign: "left", color: "white" }}
            >
              <h2
                style={{
                  color: "white",
                  fontSize: ".8em",
                  textTransform: "uppercase"
                }}
              >
                <b>REXER DESIGN Ltd. </b>
              </h2>
              <br />
              <p style={{ fontSize: ".7em" }}>
                TIN: 691-249-53-36 <br />
                NBRN: 021752181
                <br />
                NCR: 0000401052
              </p>
            </div>

            <div
              className="column"
              style={{ textAlign: "left", color: "white" }}
            >
              <h2
                style={{
                  color: "white",
                  fontSize: ".8em",
                  textTransform: "uppercase"
                }}
              >
                <b>REXER SERVICE Ltd.</b>
              </h2>
              <br />
              <p style={{ fontSize: ".7em" }}>
                TIN: 583-335-33-28 <br />
                NBRN: 383245986
                <br />
                NCR: 0000783240
              </p>
            </div>
          </div>

          <br />
          <p
            style={{
              textAlign: "center",
              fontSize: ".8em",
              textTransform: "uppercase"
            }}
          >
            <Link className="white-link" to="/en/privacy-policy/">
              Privacy policy
            </Link>{" "}
            |{" "}
            <Link className="white-link" to="/en/privacy-policy/#gdpr">
              GDPR
            </Link>{" "}
          </p>
          <br />
          <br />

          <div
            id="social-bottom"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              textTransform: "uppercase"
            }}
          >
            <p
              style={{
                color: "lightgray",
                fontSize: ".7em",
                textAlign: "center",
                minWidth: "30%"
              }}
            >
              © 2014 - {new Date().getFullYear()},{` Copyrights `}
              <Link className="white-link" to="/">
                Rexer
              </Link>
            </p>

            <div id="socials" style={{ minWidth: "30%", textAlign: "center" }}>
              <a
                className="top-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/rexer.inzynieria"
              >
                <img
                  loading="lazy"
                  className="social-icon"
                  src="/img/facebook-square.svg"
                  alt="Rexer - inżynieria środowiska"
                  style={{ marginBottom: "0px" }}
                />
              </a>
              <a
                className="top-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/11857817/"
              >
                <img
                  loading="lazy"
                  className="social-icon"
                  src="/img/linkedin.svg"
                  alt="Rexer - inżynieria środowiska"
                  style={{ marginBottom: "0px" }}
                />
              </a>
              <a
                className="top-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/rexer_pl/"
              >
                <img
                  loading="lazy"
                  className="social-icon"
                  src="/img/instagram.svg"
                  alt="Rexer - inżynieria środowiska"
                  style={{ marginBottom: "0px" }}
                />
              </a>
            </div>

            <p
              style={{
                color: "lightgray",
                fontSize: ".7em",
                textAlign: "center",
                minWidth: "30%"
              }}
            >
              Web design & development by:
              <a
                target="_blank"
                className="white-link"
                rel="noopener noreferrer"
                href="https://artopen.co"
              >
                {" "}
                Art Open
              </a>{" "}
              |{" "}
              <Link className="white-link" to="/sitemap.xml">
                SITEMAP
              </Link>
            </p>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
