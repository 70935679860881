import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";

class NavBar extends React.Component {
  componentDidMount() {
    //Check pageYoffset on scroll and add / remove class for topNav
    window.onscroll = function() {
      let top = window.pageYOffset;
      if (top > 0) {
        document.getElementById("topNav").style.top = "0px";
        document.getElementById("b2t").style.display = "block";
        document.getElementById("contact-call").style.top = "-5px";
      } else {
        document.getElementById("topNav").style.top = "30px";
        document.getElementById("b2t").style.display = "none";
        document.getElementById("contact-call").style.top = "25px";
      }
    };

    for (
      let i = 0;
      i <
      document.querySelectorAll(
        "#navbarTransparent > div.navbar-start > div > a"
      ).length;
      i++
    ) {
      document
        .querySelectorAll("#navbarTransparent > div.navbar-start > div > a")
        [i].addEventListener("mouseover", () => {
          document
            .querySelectorAll("#navbarTransparent > div.navbar-start > div > a")
            [i].nextSibling.classList.remove("is-hidden");
        });
      document
        .getElementsByClassName("navbar-dropdown")
        [i].addEventListener("mouseover", () => {
          document
            .getElementsByClassName("navbar-dropdown")
            [i].classList.remove("is-hidden");
        });
      document
        .getElementsByClassName("navbar-dropdown")
        [i].addEventListener("mouseout", () => {
          document
            .getElementsByClassName("navbar-dropdown")
            [i].classList.add("is-hidden");
        });
    }

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );

    // Add a click event on each of them
    $navbarBurgers.forEach(el => {
      el.addEventListener("click", () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const wtarget = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        wtarget.classList.toggle("is-active");
      });
    });
  }

  render() {
    const { data } = this.props;
    let posts = data.allMdx.edges;
    posts = posts.filter(post => post.node.frontmatter.language === "English");
    let cats = [];
    let pposts = posts.filter(
      post => post.node.frontmatter.templateKey === "portfolio-page"
    );
    pposts.forEach(post => {
      if (!cats.includes(post.node.frontmatter.category)) {
        cats.push(post.node.frontmatter.category);
      }
    });

    return (
      <>
        <div
          id="topNavigation"
          style={{
            width: "100%",
            display: "flex",
            position: "absolute",
            zIndex: "10",
            height: "30px",
            top: "0px",
            background: "white",
            justifyContent: "space-between"
          }}
        >
          <div
            onMouseOver={showFlags}
            onFocus={showFlags}
            onBlur={showFlags}
            onMouseOut={hideFlags}
            style={{
              display: "flex",
              width: "min-width",
              alignItems: "center"
            }}
          >
            <span>
              <img
                className="flag-icon"
                width="22px"
                height="auto"
                src="/img/usa.png"
                alt="EN"
              />
            </span>
            <Link
              aria-label="English"
              className="gray-flag is-hidden"
              rel="noopener noreferrer"
              to="/"
            >
              <img
                id="active-flag"
                className="flag-icon"
                width="22px"
                height="auto"
                src="/img/poland.png"
                alt="PL"
              />
            </Link>
            <Link
              aria-label="German"
              className="gray-flag is-hidden"
              rel="noopener noreferrer"
              to="/de/"
            >
              <img
                className="flag-icon"
                width="22px"
                height="auto"
                src="/img/germany.png"
                alt="DE"
              />
            </Link>
            <Link
              aria-label="Korean"
              className="gray-flag is-hidden"
              rel="noopener noreferrer"
              to="/ko/"
            >
              <img
                className="flag-icon"
                width="22px"
                height="auto"
                src="/img/south-korea.png"
                alt="KO"
              />
            </Link>
            <span
              className="mob-hid"
              style={{ display: "flex", alignItems: "center" }}
            >
              |{" "}
              <a
                className="top-link small-s"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/rexer.inzynieria"
              >
                <img
                  style={{ marginBottom: "0px" }}
                  src="/img/facebook-square.svg"
                  alt="Rexer - inżynieria środowiska"
                  width="15"
                  height="15"
                />
              </a>
              <a
                className="top-link small-s"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/11857817/"
              >
                <img
                  style={{ marginBottom: "0px" }}
                  src="/img/linkedin.svg"
                  alt="Rexer - inżynieria środowiska"
                  width="15"
                  height="15"
                />
              </a>
              <a
                className="top-link small-s"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/rexer_pl/"
              >
                <img
                  style={{ marginBottom: "0px" }}
                  src="/img/instagram.svg"
                  alt="Rexer - inżynieria środowiska"
                  width="15"
                  height="15"
                />
              </a>
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <a
              className="top-link small-s"
              style={{
                color: "#111111",
                padding: "0px 5px",
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
                fontSize: ".7em"
              }}
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:biuro@rexer.pl"
            >
              <img
                style={{ marginBottom: "0px" }}
                src="/img/envelope-solid.svg"
                alt="Rexer - mail"
                width="15px"
                height="15px"
              />
              &nbsp;&nbsp;biuro@rexer.pl
            </a>
            <a
              id="hide-on-mob"
              className="top-link small-s"
              style={{
                color: "#111111",
                padding: "0px 5px",
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
                fontSize: ".7em"
              }}
              href="tel:+48 71 339 81 56"
            >
              <img
                style={{ marginBottom: "0px" }}
                src="/img/mobile-alt-solid.svg"
                alt="Rexer - mobile"
                width="15px"
                height="15px"
              />
              &nbsp;&nbsp;+48 71 339 81 56
            </a>
          </div>
        </div>

        <nav
          className="navbar is-white is-fixed-top"
          id="topNav"
          style={{ width: "100%", top: "30px" }}
        >
          <div className="navbar-brand">
            <Link
              className="navbar-item"
              to="/en/"
              style={{
                boxShadow: "5px 5px #00ff00",
                backgroundColor: "black",
                outlineWidth: "0"
              }}
            >
              <img
                style={{ marginBottom: "0px" }}
                src="/img/logo-rexer.png"
                alt="Rexer - inżynieria środowiska"
                width="112"
                height="28"
              />
            </Link>
            <div className="navbar-burger" data-target="navbarTransparent">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div id="navbarTransparent" className="navbar-menu is-light">
            <div className="navbar-start">
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/o-nas/">
                  About us
                </Link>
                <div className="navbar-dropdown is-boxed is-hidden">
                  <Link to={"/o-nas#team"} className="navbar-item is-sub">
                    Our team
                  </Link>
                  <Link
                    className="navbar-item is-sub"
                    to="/unia-europejska/"
                    id="eu"
                  >
                    EU
                  </Link>
                </div>
              </div>

              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/oferta/">
                  Offer
                </Link>
                <div className="navbar-dropdown is-boxed is-hidden">
                  {/*Map offer posts*/}
                  {posts &&
                    posts
                      .filter(
                        post =>
                          post.node.frontmatter.templateKey === "offer-page"
                      )
                      .slice(0, 7)
                      .map(({ node: post }) => (
                        <Link
                          to={"/" + post.fields.slug}
                          key={post.id}
                          className="navbar-item is-sub"
                        >
                          {post.frontmatter.title}
                        </Link>
                      ))}
                </div>
              </div>

              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/realizacje/">
                  Portfolio
                </Link>
                <div className="navbar-dropdown is-boxed is-hidden">
                  {/*Map portfolio posts*/}
                  {cats &&
                    cats.map(cat => (
                      <Link
                        to={
                          "/realizacje/" +
                          cat
                            .normalize("NFD")
                            // remove accents - diacritics - not effective for Polish letter ł
                            .replace(/[\u0300-\u036f]/g, "")
                            // letter ł
                            .replace(/['ł']/g, "l")
                            // letter ż
                            .replace(/['ż']/g, "z")
                            // change whitespace to -
                            .replace(/\s+/g, "-")
                            // remove special chars except for -
                            .replace(
                              /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\]/gi,
                              ""
                            )
                            // remove emoji
                            .replace(
                              /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
                              ""
                            )
                            .toLowerCase() +
                          "/"
                        }
                        key={cat}
                        className="navbar-item is-sub"
                      >
                        {cat}
                      </Link>
                    ))}
                </div>
              </div>

              <Link className="navbar-item navbar-link" to="/aktualnosci/">
                Blog
              </Link>

              <Link className="navbar-item navbar-link" to="/sport/">
                Sport
              </Link>

              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/kariera/">
                  Career
                </Link>
                <div className="navbar-dropdown is-boxed is-hidden">
                  {/*Map career posts*/}
                  {posts &&
                    posts
                      .filter(
                        post =>
                          post.node.frontmatter.templateKey === "career-page"
                      )
                      .slice(0, 7)
                      .map(({ node: post }) => (
                        <Link
                          to={"/" + post.fields.slug}
                          key={post.id}
                          className="navbar-item is-sub"
                        >
                          {post.frontmatter.title}
                        </Link>
                      ))}
                </div>
              </div>

              {/*<img className="flag-icon" src="/img/eu-flag.png" width="25px" height="auto" alt="ue" />&nbsp;&nbsp;*/}
            </div>
            <div className="navbar-end">
              <div
                className="navbar-item"
                style={{ boxShadow: "#00ff00 0px 0px" }}
              >
                <div className="field is-grouped"></div>
              </div>
            </div>
          </div>

          <Link to="/kontakt/">
            <button
              className="button standard-button"
              style={{
                position: "fixed",
                top: "25px",
                right: "-10px",
                transform: "rotateY(180deg)",
                marginTop: "5px",
                minHeight: "53px",
                width: "136px",
                backgroundColor: "black",
                boxShadow: "5px 5px #00ff00"
              }}
              id="contact-call"
            >
              <span
                style={{
                  fontWeight: "400",
                  textTransform: "uppercase",
                  color: "white",
                  transform: "rotateY(180deg)"
                }}
              >
                Contact us
              </span>
            </button>
          </Link>

          <a
            id="b2t"
            href="#top"
            style={{
              position: "fixed",
              right: "1px",
              bottom: "1px",
              border: "0px solid white",
              transform: "rotate(180deg)",
              display: "none"
            }}
            className="button standard-button"
          >
            ↓
          </a>
        </nav>
      </>
    );
  }
}

function showFlags() {
  for (
    let i = 0;
    i < document.getElementsByClassName("gray-flag").length;
    i++
  ) {
    document
      .getElementsByClassName("gray-flag")
      [i].classList.remove("is-hidden");
  }
}

function hideFlags() {
  for (
    let i = 0;
    i < document.getElementsByClassName("gray-flag").length;
    i++
  ) {
    document.getElementsByClassName("gray-flag")[i].classList.add("is-hidden");
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 40) {
          edges {
            node {
              id
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD. MM. YYYY")
                title
                language
                category
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NavBar data={data} count={count} />}
  />
);
