import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "./../styles.sass"
import NavBar from "./navbar.js"
import Footer from "./footer.js"



class Layout extends React.Component {


  componentDidMount() {
    checkCookies();
  }


  render() {
    const { children } = this.props

    return (
      <Wrapper>
      <NavBar />

        <div
          style={{
            marginLeft: `0`,
            marginRight: `0`,
            maxWidth: '100%',
          }}
        >

          <main>{children}</main>

        </div>


        <Footer />
        <article id="cookie-notice" style={{zIndex:'1000',padding:'2%',backgroundColor:'#111111'}}>

          <div style={{textAlign:'left',display:'flex'}}>
          <img loading="lazy" alt="pliki cookies" style={{filter:'invert(1)',marginRight:'20px',height:'25px'}} src="/img/cookie-solid.svg" width="25px" height="25px" />
          <p style={{color:'white',fontSize:'.9em'}}>The website uses cookies to the extent described in <Link to="/en/privacy-policy/">Privacy Policy</Link>. By accessing this website, you agree to their use, in accordance with your browser settings.
           Remember that you can always change the conditions of storing cookies and access them by changing the settings of the web browser you use.</p>
          <button onClick={setCookies} className="delete" aria-label="delete"></button>
          </div>
           <div style={{textAlign:'center'}}>
           <button onClick={setCookies} className="button rexer-button button-border is-size-6" >Accept</button>
           <button className="button rexer-button button-border is-size-6" onClick={()=>{window.location.href='/en/privacy-policy/'}}>Privacy Policy</button>
          </div>
        </article>
      </Wrapper>
    )
  }
}



const Wrapper = styled.div`
  min-height: 100vh;
`


function setCookies() {
  //console.log('workin');
    if (global.localStorage.getItem('cookies')!== 'accepted'){
    global.localStorage.setItem("cookies", "accepted");
    document.getElementById('cookie-notice').style.display = 'none';
    }
    else {
      document.getElementById('cookie-notice').style.display = 'none';
    }
};

function checkCookies() {
  if (global.localStorage.getItem('cookies') !== 'accepted'){
    document.getElementById('cookie-notice').style.display = 'block';
    } else {
    document.getElementById('cookie-notice').style.display = 'none';
    }
};


export default Layout
